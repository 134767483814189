import { AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { ChannelType, CreateChannelInput, Maybe } from '@designage/gql';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { IChannelForm, ICreateChannelForm } from '@desquare/interfaces';
import { ChannelService, CurrentUserService } from '@desquare/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { lastValueFrom } from 'rxjs';
import { ChannelFormComponent } from '../channel-form/channel-form.component';
import { TranslatePipe } from '@ngx-translate/core';

interface IForm {
  channel?: ICreateChannelForm;
}

@Component({
  standalone: true,
  imports: [
    TranslatePipe,

    FormDialogComponent,
    LoaderComponent,
    ChannelFormComponent,
  ],
  selector: 'app-create-event-dialog',
  template: `<app-form-dialog
    closeButtonText="NEXT"
    [valid]="form.valid"
    [pristine]="form.pristine"
    [values]="values"
    dismissButtonText="CANCEL"
    headerText="{{ 'EVENT_ADD_EVENT' | translate }}"
  >
    <app-loader [message]="loaderMessage" *ngIf="loading"></app-loader>
    <app-channel-form
      [type]="EventChannelType"
      [profileId]="profileId"
      [isDashboardView]="true"
      [parentFormGroup]="form"
      [formId]="channel?.id || 'new-event'"
      [showMiniLayoutExplorer]="true"
      (values)="setValues($event)"
    ></app-channel-form>
  </app-form-dialog> `,
})
export class CreateEventDialogComponent implements AfterViewInit {
  newChannel: ICreateChannelForm = {
    id: '',
    name: 'new event',
    type: ChannelType.Event,
    layoutId: '',
    userId: '',
    profileId: '',
  };

  formValue: IForm = {
    channel: this.newChannel,
  };

  // form = new FormGroup({ channel: new FormGroup({}) });
  form: FormGroup = new UntypedFormGroup({});

  values!: ICreateChannelForm;
  loading = false;
  loaderMessage!: string;
  isFormValid = false;
  isFormPristine = true;
  profileId!: Maybe<string>;
  channel!: Maybe<ICreateChannelForm>;
  readonly EventChannelType = ChannelType.Event;
  /** connect to this deviceId */
  deviceId?: string;

  constructor(
    public modal: NgbActiveModal,
    private channelService: ChannelService,
    private currentUserService: CurrentUserService,
    private cdr: ChangeDetectorRef,
  ) {}
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  cancel() {
    this.modal.dismiss();
  }

  async confirm() {
    /* const createEvent:IChannelForm = {
      name: this.form.values.name
      profileId=this.profileId,
      type: ChannelType.Event,

    }*/

    // const input: ICreateChannelForm = this.form.controls.channel.value;

    // console.log('createEvent', this.form.controls.channel.value);
    // console.log('input', input);
    console.log('values', this.values);

    this.values.userId = this.currentUserService.currentUser!.id;
    this.values.eventDeviceId = this.deviceId;

    const { name, description, layoutId, eventStart, eventEnd } = this.values;
    const input: CreateChannelInput = {
      name,
      description,
      layoutId,
      profileId: this.currentUserService.getCurrentProfileId(),
      type: ChannelType.Event,
      eventStart,
      eventEnd,
      eventDeviceId: this.deviceId,
    };
    console.log('create event input:', input);

    const { data } = await lastValueFrom(
      this.channelService.createChannel(input),
    );
    const event = data?.createChannel.channel;

    if (event) {
      console.log('saved event');
      // this.modal.close(event);
    }
  }
  setValues(e: IChannelForm) {
    this.values = e as ICreateChannelForm;
  }
}
